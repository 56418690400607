import { TECH } from "content/tech-stack";
import Aiko from "../public/images/projects/Aiko.jpg";
import AlphabetPIX from "../public/images/projects/AlphabetPix.jpg";
import BroadwayJewellers from "../public/images/projects/BroadwayJewellers.jpg";
import BushScarf from "../public/images/projects/BushScarf.jpg";
import Woise from "../public/images/projects/Woise.jpg";
import beam from "../public/images/projects/beam.png";
import ErplyEgypt from "../public/images/projects/Erply Egypt.png";
import ErplyTakealot from "../public/images/projects/Erply Takealot.png";
import FxStyle from "../public/images/projects/fx-style.png";
import myPortfolio from "../public/images/projects/myPortfolio.jpg";
import Lunalemon from "../public/images/projects/Lunalemon.jpg";
import Damasco from "../public/images/projects/Damasco.jpg";
import ShopifyCourierGuy from "../public/images/projects/Shopify CourierGuy.png";
import ShopifyDear from "../public/images/projects/Shopify Dear Systems.png";
import VendShopifyOmnisend from "../public/images/projects/Shopify Vend Omnisend.png";
import ShopifyVend from "../public/images/projects/Shopify Vend.png";
import ShopifyCms from "../public/images/projects/Shopify-cms.png";
import ShopifyFtp from "../public/images/projects/shopify-ftp.png";
import ShopifyTypedNodeApi from "../public/images/projects/shopify-typed-node-api.png";
import manDatary from "../public/images/projects/manDatary.jpg";
import VendTakealot from "../public/images/projects/Vend Takealot.png";
import WaraqHealth from "../public/images/projects/Waraq.jpg";
import ZoomPrinting from "../public/images/projects/ZoomPringing.jpg";
import grpcrust from "../public/images/projects/grpc-rust.png";
import crudapirust from "../public/images/projects/rust-api-crud.jpg";
import fastapimicroservice from "../public/images/projects/fastapimicroservice.jpg";

export const PROJECTS = [
  {
    name: "London Rides: Apache Beam [GCP]",
    type: ["Data Pipeline"],
    tech: [TECH.python, TECH.googleCloud, TECH.apacheAirflow],
    //url: "https://github.com/mattummal/gcp-london-rides",
    repository: "https://github.com/mattummal/gcp-london-rides",
    description: `A data pipeline built in Python using Apache Beam on Google Cloud Platform (GCP).`,
    content: (
      <>
        <p>
          I've built a data pipeline in Python using Apache Beam on Google Cloud Platform (GCP) to process and analyse London bicycle rides data.
        </p>
      </>
    ),
    featuredImage: beam,
    year: "2025",
  },
  {
    name: "Football Games: Crud API",
    type: ["CRUD API"],
    tech: [TECH.rust, TECH.docker],
    //url: "https://gitlab.com/mattummal/rust-football-crud-api",
    repository: "https://gitlab.com/mattummal/rust-football-crud-api",
    description: `A high-performance CRUD API built in Rust using Actix Web and SQLx for robust, asynchronous handling of database operations.`,
    content: (
      <>
        <p>
          I have built a high-performance CRUD API purely in Rust, using Docker, SQLx and Actix Web.
        </p>
      </>
    ),
    featuredImage: crudapirust,
    year: "2024",
  },
  {
    name: "Order/Transaction FastAPI & Redis: Microservices",
    type: ["CRUD API"],
    tech: [TECH.python, TECH.redis, TECH.react, TECH.typescript],
    //url: "https://github.com/mattummal/transactions-fastApi-redis",
    repository: "https://github.com/mattummal/transactions-fastApi-redis",
    description: `A Order/Transaction FastAPI built in Python using FastAPI and Redis for robust, asynchronous handling of database operations.`,
    content: (
      <>
        <p> I have built a high-performance FastAPI built in Python using FastAPI and Redis. </p>
      </>
    ),
    featuredImage: fastapimicroservice,
    year: "2024",
  },
  {
    name: "BTC Payment (gRPC): Microservice",
    type: ["Microservice Web Application"],
    tech: [TECH.rust, TECH.docker],
    //url: "https://github.com/mattummal/btc-pay_gRPC",
    repository: "https://github.com/mattummal/btc-pay_gRPC",
    description: `Built a Bitcoin Payment Microservice (gRPC) purely in Rust, using Tokio, Tonic and Prost.`,
    content: (
      <>
        <p>
          I have built a Bitcoin Payment Microservice (gRPC) purely in Rust, using Tokio, Tonic and Prost.
        </p>
      </>
    ),
    featuredImage: grpcrust,
    year: "2024",
  },
  {
    name: "Woise: WebApp",
    type: ["Web Application"],
    tech: [
      TECH.python,
      TECH.vercel,
      TECH.nextjs,
      TECH.reactjs,
      TECH.tailwindcss,
      TECH.prisma,
      TECH.planetscale,
      TECH.trpc,
      TECH.aws,
      TECH.turborepo,
    ],
    url: "https://mda-woise.streamlit.app/",
    repository: "https://gitlab.com/mattummal/Woise",
    description: `A prediction model based on forecast weather and air quality data. By utilising machine learning models, we aim to provide valuable insights into the noise levels in the city, enabling residents and authorities to better understand and manage noise pollution.`,
    content: (
      <>
        <p>
        Our app predicts noise levels in Leuven city using weather and air quality data. With accurate forecasts, you can plan activities, minimize disruptions, and maintain a peaceful environment.
        Join us in creating a harmonious city experience with Woise.
        </p>
      </>
    ),
    featuredImage: Woise,
    year: "2022",
  },
  {
    name: "Damasco: Restaurant Site",
    type: ["Restaurant Website"],
    tech: [
      TECH.typescript,
      TECH.vercel,
      TECH.nextjs,
      TECH.reactjs,
      TECH.tailwindcss,
      TECH.prisma,
      TECH.planetscale,
      TECH.trpc,
      TECH.jsdom,
    ],
    url: "https://damascoleuven.vercel.app",
    repository: "https://github.com/mattummal/damascoo",
    description: `I've developed a restaurant website using TypeScript, HTML, and CSS, offering an enhanced dining experience for customers. The website features intuitive navigation and interactive elements to showcase the restaurant's offerings.`,
    content: (
      <>
        <p>
        I created this website to provide an immersive online experience for restaurant visitors.
        The site's design emphasizes user engagement and ease of use, with seamless integration of menus, reservation systems, and promotional content.
        </p>
        <p>
        My development process encountered various challenges, including responsive design implementation, performance optimization, and cross-browser compatibility.
        However, I successfully overcame these obstacles to deliver a polished and functional website.
        </p>
      </>
    ),
    featuredImage: Damasco,
    year: "2024",
  },
  {
    name: "manDatary: SaaS Site",
    type: ["SaaS Website"],
    tech: [TECH.typescript, TECH.vercel, TECH.nextjs, TECH.reactjs],
    url: "https://mandatary.com.de",
    repository: "https://github.com/mandatary/mandatary.com.de",
    description: `manDatary Shopify Ecommerce Website. I've built the site to establish a basis to find new Web Development projects and promote Shopify services.`,
    content: (
      <>
        <p>
          I have created the manDatary website in 2023 to establish a basis to find new Web Development projects.
        </p>
      </>
    ),
    featuredImage: manDatary,
    year: "2023",
  },
  {
    name: "mattumm.al: Techfolio",
    type: ["Techfolio Website"],
    tech: [TECH.python, TECH.typescript, TECH.vercel, TECH.nodejs],
    url: "https://mattumm.al",
    repository: "",
    description: `Showcasing my journey through creativity and innovation—explore my projects, skills, and professional growth.`,
    content: (
      <>
        <p>
        With its responsive layout and captivating visuals, the website offers visitors an engaging experience while highlighting my expertise and projects.
        Explore my portfolio, learn about my journey, and discover the range of projects I've undertaken.
        Welcome to my digital showcase.
        </p>
      </>
    ),
    featuredImage: myPortfolio,
    year: "2021",
  },
  /*
  {
    name: "Lunalemon",
    type: ["Marketing Sites"],
    tech: [
      TECH.shopify,
      TECH.typescript,
      TECH.trpc,
      TECH.tailwindcss,
      TECH.nextjs,
      TECH.vercel,
      TECH.headlessui,
      TECH.planetscale,
    ],
    description:
      "Headless Web agency site utilizing Shopify's theme editor as a custom CMS. The site showcases my client oriented projects and promotes web development services.",
    featuredImage: Lunalemon,
    year: "2022",
    url: "https://kidsliving.co.za",
    repository: "https://github.com/mattummal/lunalemon.dev",
  },
  {
    name: "shopify-ftp-access",
    type: ["Devtools"],
    tech: [TECH.typescript, TECH.swc, TECH.shopify],
    year: "2017",
    repository: "https://github.com/mattummal/shopify-ftp-access",
    description:
      "Shopify-ftp-access is an Open Source utility which acts as a proxy that allows you to use any FTP program while running the CLI to connect upload/download any shopify Theme files",
    featuredImage: ShopifyFtp,
  },
  {
    name: "Erply Egypt eInvoicing API",
    type: ["Integrations"],
    tech: [TECH.nextjs, TECH.aws_lambda, TECH.axios],
    year: "2022",
    description:
      "This custom App runs on 5 min Cron Job to process any incoming sales data for a retail chain in Egypt, processing each invoice on the eInvoicing portal. The app includes an internal dashboard for any manual processing required.",
    featuredImage: ErplyEgypt,
  },
  {
    name: "Zoom Printing",
    type: ["Ecommerce Sites"],
    tech: [TECH.shopify, TECH.typescript, TECH.tailwindcss],
    year: "2021",
    url: "https://www.zoomprinting.ca/",
    description:
      "I've built the Shopify site based on the Warehouse theme, with lots of custom integrations to enable up to 1000 product variants and add-on sales. I've migrated the client from a old legacy self-hosted server, building my own web-scrapers along the way.",
    featuredImage: ZoomPrinting,
  },
  {
    name: "Vend Takealot API",
    type: ["Integrations"],
    tech: [TECH.nextjs, TECH.aws_lambda, TECH.axios, TECH.vend],
    year: "2021",
    description:
      "Similar to the Erply Takealot integration, this app runs a scheduled script to ensure that sales & inventory levels are synchronized between two sales channels.",
    featuredImage: VendTakealot,
  },
  {
    name: "Aiko",
    type: ["Ecommerce Sites"],
    tech: [TECH.figma, TECH.shopify, TECH.tailwindcss, TECH.typescript],
    year: "2022",
    url: "https://aikoplanet.com/",
    description:
      "I built a fully custom designed Shopify site, utilizing shopify-cms in a non-headless way, to ensure type safety throughout the project using Shopify Liquid templates.",
    featuredImage: Aiko,
  },
  {
    name: "Shopify Courier Guy API",
    type: ["Integrations"],
    tech: [TECH.nextjs, TECH.aws_lambda, TECH.axios, TECH.shopify],
    year: "2021",
    description:
      "An integration to connect sales directly to the popular shipping provided Courier Guy, reducing the need to manually process incoming orders for fulfillment.",
    featuredImage: ShopifyCourierGuy,
  },
  {
    name: "shopify-cms",
    type: ["Devtools"],
    tech: [TECH.shopify, TECH.typescript, TECH.graphql, TECH.reactjs, TECH.tailwindcss],
    year: "2022",
    repository: "https://github.com/mattummal/shopify-cms",
    description:
      "Shopify-cms is another Open Source CLI that I've built. The package helps building a site with the Shopify Theme Editor as a Headless CMS and get fully typed data.",
    featuredImage: ShopifyCms,
  },
  {
    name: "Erply Takealot sales API",
    type: ["Integrations"],
    tech: [TECH.nextjs, TECH.aws_lambda, TECH.axios],
    year: "2021",
    description:
      "I've built an API integration to process inventory levels and sales between the two POS/Ecommerce platforms for an internal client.",
    featuredImage: ErplyTakealot,
  },
  {
    name: "AlphabetPIX",
    type: ["Ecommerce Sites"],
    tech: [TECH.shopify, TECH.javascript, TECH.sass],
    year: "2017",
    url: "https://alphabetpix.com/",
    description:
      "Custom build Shopify-site / Web-app that allows customers to create their own named picture frame and generate image files on the fly for their checkout.",
    featuredImage: AlphabetPIX,
  },
  {
    name: "shopify-typed-node-api",
    type: ["Devtools"],
    tech: [TECH.shopify, TECH.typescript, TECH.graphql, TECH.reactjs, TECH.nodejs],
    year: "2021",
    repository: "https://github.com/mattummal/shopify-typed-node-api/",
    description:
      "Shopify-typed-node-api is an extension to the popular shopify-node-api, adding typescript support for all Rest & GraphQL endpoints provided by Shopify.",
    featuredImage: ShopifyTypedNodeApi,
  },
  {
    name: "Shopify Vend product API",
    type: ["Integrations"],
    tech: [TECH.nextjs, TECH.aws_lambda, TECH.axios, TECH.graphql, TECH.shopify, TECH.vend],
    year: "2020",
    description:
      "A custom integration, ensuring that relevant product data is kept synchronized between Vend POS and Shopify, based on a webhook architecture to push updates.",
    featuredImage: ShopifyVend,
  },
  {
    name: "BushScarf",
    type: ["Ecommerce Sites"],
    tech: [TECH.shopify, TECH.javascript, TECH.sass],
    year: "2016",
    url: "https://originalthings-za.myshopify.com/",
    description:
      "I worked with Bushscarf to develop their Shopify Website covering aspects from product photography, product image editing and product content creation.",
    featuredImage: BushScarf,
  },
  {
    name: "Broadway Jewellers",
    type: ["Ecommerce Sites"],
    tech: [TECH.shopify, TECH.typescript, TECH.sass, TECH.aws],
    year: "2020",
    url: "https://broadwayjewellers.co.za/",
    description:
      "My work with Broadway Jewellers started with migrating their site form WordPress to Shopify, I also covered an array of aspects from inventory management, digital marketing, SEO web solutions, web design upgrade to custom API solutions.",
    featuredImage: BroadwayJewellers,
  },
  {
    name: "fx-style",
    type: ["Devtools"],
    tech: [TECH.prettier, TECH.eslint, TECH.typescript],
    year: "2020",
    repository: "https://github.com/mattummal/fx-style",
    description:
      "I've created fx-style as a package to standardize my own code style across all projects, while minimizing devDependencies for each project.",
    featuredImage: FxStyle,
  },
  {
    name: "Shopify DearSystems API",
    type: ["Integrations"],
    tech: [TECH.nextjs, TECH.aws_lambda, TECH.axios, TECH.shopify],
    year: "2022",
    description:
      "This integration allows my client to effectively synchronize their inventory between Dear and Shopify with multiple store locations and a complex VAT setup.",
    featuredImage: ShopifyDear,
  },
  {
    name: "Waraq Health",
    type: ["Ecommerce Sites"],
    tech: [TECH.shopify, TECH.typescript, TECH.sass, TECH.aws],
    year: "2021",
    url: "https://waraqhealth.com/",
    description:
      "I worked with Waraq to successfully develop their Shopify site, covering aspects from product image editing and content research and creation.",
    featuredImage: WaraqHealth,
  },
  {
    name: "Vend Shopify Omnisend API",
    type: ["Integrations"],
    tech: [TECH.nextjs, TECH.aws_lambda, TECH.axios, TECH.vend],
    year: "2020",
    description:
      "A custom integration to collect & update customer information for OmniSend email marketing, utilizing in-store (via QR code) and online sign-ups.",
    featuredImage: VendShopifyOmnisend,
  },
  */
];
