import { StarIcon } from "@heroicons/react/20/solid";
import { BookOpenIcon, BuildingOffice2Icon, CakeIcon, ChartBarIcon, CodeBracketIcon, CodeBracketSquareIcon, ComputerDesktopIcon, FireIcon, LanguageIcon, SignalIcon, UserCircleIcon, WifiIcon } from "@heroicons/react/24/solid";
import { AiOutlineDollar } from "@react-icons/all-files/ai/AiOutlineDollar";
import { BiGame } from "@react-icons/all-files/bi/BiGame";
import { BiSticker } from "@react-icons/all-files/bi/BiSticker";
import { FaBaby } from "@react-icons/all-files/fa/FaBaby";
import { FaChalkboardTeacher } from "@react-icons/all-files/fa/FaChalkboardTeacher";
import { FaGraduationCap } from "@react-icons/all-files/fa/FaGraduationCap";
import { FaPlaneDeparture } from "@react-icons/all-files/fa/FaPlaneDeparture";
import { FaQuestion } from "@react-icons/all-files/fa/FaQuestion";
import { FaTruck } from "@react-icons/all-files/fa/FaTruck";
import { FaTruckMoving } from "@react-icons/all-files/fa/FaTruckMoving";
import { GiAfrica } from "@react-icons/all-files/gi/GiAfrica";
import { GiBigDiamondRing } from "@react-icons/all-files/gi/GiBigDiamondRing";
import { GiOppositeHearts } from "@react-icons/all-files/gi/GiOppositeHearts";
import { GiShipBow } from "@react-icons/all-files/gi/GiShipBow";
import { GiSoccerBall } from "@react-icons/all-files/gi/GiSoccerBall";
import { GiSouthAfrica } from "@react-icons/all-files/gi/GiSouthAfrica";
import { GiSuspensionBridge } from "@react-icons/all-files/gi/GiSuspensionBridge";
import { GrRestaurant } from "@react-icons/all-files/gr/GrRestaurant";
import { IoMdRestaurant } from "@react-icons/all-files/io/IoMdRestaurant";
import { RiVirusLine } from "@react-icons/all-files/ri/RiVirusLine";
import { RiWalkFill } from "@react-icons/all-files/ri/RiWalkFill";
import { SiCoronaengine } from "@react-icons/all-files/si/SiCoronaengine";
import { SiShopify } from "@react-icons/all-files/si/SiShopify";
//import { BsFillPatchQuestionFill } from "react-icons/all";
import { SiJavascript } from "react-icons/si";

export const TIMELINEOBJECT = {
  "1999": [
    {
      date: "1999-09-19",
      heading: "Engineered",
      description: "Born this year, ignited the spark for exploration.",
      Icon: ({ className = "" }) => <FaBaby className={className} />,
    },
  ],
  "2005": [
    {
      date: "2005-06-01",
      heading: "Connected",
      description:
        "Embarked on the cyber highway at 6, riding the 60KBit modem wave, expanding horizons 🤯",
      Icon: ({ className = "" }) => <WifiIcon className={className} />,
    },
  ],
  "2006": [
    {
      date: "2006-01-01",
      heading: "Tech Assembly",
      description:
        "First computer crafted with a blend of inherited hardware and new components, a 133Mhz Pentium powerhouse with 8MB RAM 😂",
      Icon: ({ className = "" }) => <ComputerDesktopIcon className={className} />,
    },
    {
      date: "2006-06-01",
      heading: "Coding Ignition",
      description:
        "Ignited programming passion with mentor Franz, coding the essentials: calculator and a pixel-perfect racing game with C and C++.",
      Icon: ({ className = "" }) => <CodeBracketIcon className={className} />,
    },
  ],
  "2007": [
    {
      date: "2007-01-01",
      heading: "Tech Odyssey",
      description:
        "Tech pilgrimage to Dubai, delving into the digital realm, and acquiring the mighty Laser Mouse 🖱",
      Icon: ({ className = "" }) => <GiSuspensionBridge className={className} />,
    },
    {
      date: "2007-05-01",
      heading: "Gaming Thrust",
      description:
        "Dove into gaming culture and LAN parties, mastering Half-life, Diablo I, and StarCraft between network troubleshooting sessions",
      Icon: ({ className = "" }) => <BiGame className={className} />,
    },
  ],
  "2008": [
    {
      date: "2008-01-01",
      heading: "Web Wizardry",
      description: "Embarked on web development quests with Adobe Dreamweaver, conquering the enigmatic Frames 🙃",
      Icon: ({ className = "" }) => <CodeBracketSquareIcon className={className} />,
    },
  ],
  "2009": [
    {
      date: "2009-01-01",
      heading: "Game Crafting",
      description:
        "Stepped into the realm of game modding, experimenting extensively with Counter Strike and the HL engine.",
      Icon: ({ className = "" }) => <SiCoronaengine className={className} />,
    },
  ],
  "2014": [
    {
      date: "2014-01-01",
      heading: "Engine Exploration",
      description:
        "Embarked on a journey to Himalayas, exploring the scenic routes in the mountains.",
      Icon: ({ className = "" }) => <GiAfrica className={className} />,
    },
  ],
  "2017": [
    {
      date: "2017-01-01",
      heading: "High-Tech Triumph",
      description:
        "Achieved high-school graduation in Sciences, transitioning to various tech endeavors.",
      Icon: ({ className = "" }) => <FaGraduationCap className={className} />,
    },
  ],
  "2018": [
    {
      date: "2018-01-01",
      heading: "Navigating Uncertainty",
      description:
        "Navigated through the maze of post-graduation uncertainty, questioning the alignment of skills with career paths.",
      Icon: ({ className = "" }) => <FaQuestion className={className} />,
    },
  ],
  "2019": [
    {
      date: "2019-01-01",
      heading: "Southbound Revisit",
      description:
        "Embarked on an expedition to Bangalore, immersing in the beauty of White Field and its technological allure.",
      Icon: ({ className = "" }) => <GiSouthAfrica className={className} />,
    },
  ],

  "2020": [
    {
      date: "2020-01-01",
      heading: "Revving Up",
      description:
        "At the age of 21, embarked on a thrilling journey into the world of motorcycles, igniting a passion for the intricate dance of engines and gears.",
      Icon: ({ className = "" }) => <StarIcon className={className} />,
    },
    {
      date: "2020-05-01",
      heading: "Cross-Country Cruise",
      description:
        "Took a transcontinental ride, swapping German autobahns for the scenic roads of Lithuania, fueling my love for exploration on two wheels.",
      Icon: ({ className = "" }) => <GiSouthAfrica className={className} />,
    },
    {
      date: "2020-08-01",
      heading: "Coding Breakthrough",
      description:
        "Delved deep into the digital domain, mastering new coding languages and frameworks, enhancing my skills in software and hardware engineering.",
      Icon: ({ className = "" }) => <SiJavascript className={className} />,
    },
  ],
  "2021": [
    {
      date: "2021-01-01",
      heading: "Academic Pursuit",
      description: "Embarked on an exhilarating journey pursuing an M.Sc. degree, delving deeper into the realms of technology and mathematics.",
      Icon: ({ className = "" }) => <FaGraduationCap className={className} />,
    },
    {
      date: "2021-09-01",
      heading: "Navigating Setbacks",
      description: "Encountered a setback as the establishment closed down due to the financial crisis, redirecting my path and resilience towards new opportunities in the dynamic world of technology.",
      Icon: ({ className = "" }) => <GrRestaurant className={className} />,
    },
  ],
  "2022": [
    {
      date: "2022-01-01",
      heading: "Full-time Study",
      description:
        "I once again, moved back. This time to stay for at least 3 years to study CompSci/Stats/MLDS.",
      Icon: ({ className = "" }) => <BookOpenIcon className={className} />,
    },
    {
      date: "2022-01-01",
      heading: "World Cup 2022",
      description: "It was amazing to witness the World Cup 2022.",
      Icon: ({ className = "" }) => <GiSoccerBall className={className} />,
    },
    {
      date: "2022-06-01",
      heading: "Graduated Varsity",
      description:
        "I graduated with three Higher National Diplomas Summa Cum Laude, covering all aspects I needed to know to one day open my own restaurant",
      Icon: ({ className = "" }) => <FaGraduationCap className={className} />,
    },
    {
      date: "2022-01-01",
      heading: "Reconnecting the dots",
      description:
        "I was exposed to a lot of IT & Web dev work for the restaurant. I reconnected a lot of my teenage skill with IT and learned more about POS systems.",
      Icon: ({ className = "" }) => <GiOppositeHearts className={className} />,
    },
    {
      date: "2022-06-01",
      heading: "Personal Website",
      description:
        "I decided to build my site with WordPress, showing a gallery and info about me. Unfortunately, I didn't use Git yet and lost the source code on a old HDD.",
      Icon: ({ className = "" }) => <ComputerDesktopIcon className={className} />,
    },
    {
      date: "2022-02-01",
      heading: "Backend API integrations",
      description:
        "Over the year I got heavily involved in some backend custom integrations, building solutions with AWS for our clients.",
      Icon: ({ className = "" }) => <StarIcon className={className} />,
    },
  ],
  "2023": [
    {
      date: "2023-01-01",
      heading: "Tech Venture",
      description:
        "Launched manDatary, a brand aimed at international audiences, specializing in app and headless ecommerce solutions, marking the beginning of a new chapter in tech entrepreneurship.",
      Icon: ({ className = "" }) => <ComputerDesktopIcon className={className} />,
    },
    {
      date: "2023-09-01",
      heading: "Academic Pursuit",
      description:
        "Commenced an M.Sc. program in Applied Computer Science, diving deeper into the intricate world of software, hardware, and mathematics.",
      Icon: ({ className = "" }) => <FaGraduationCap className={className} />,
    },
  ],

  "2024": [
    {
      date: "2024-01-01",
      heading: "Vintage Ride",
      description:
        "Acquired a classic Kawasaki KZ1100, igniting a new passion for vintage motorcycles and the art of engine craftsmanship.",
      Icon: ({ className = "" }) => <StarIcon className={className} />,
    },
  ],
};
